import React, { Component } from 'react';
import PropTypes from 'prop-types';

var classCallCheck = function (instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
};

var createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();









var inherits = function (subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
};











var possibleConstructorReturn = function (self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
};

var FeatureFlags = React.createContext();

var Flags = function (_Component) {
  inherits(Flags, _Component);

  function Flags() {
    classCallCheck(this, Flags);
    return possibleConstructorReturn(this, (Flags.__proto__ || Object.getPrototypeOf(Flags)).apply(this, arguments));
  }

  createClass(Flags, [{
    key: 'matchingFlags',
    value: function matchingFlags(flags) {
      var _this2 = this;

      return flags.filter(function (flag) {
        return flag.isActive && _this2.props.authorizedFlags.includes(flag.name);
      });
    }
  }, {
    key: 'resolveRender',
    value: function resolveRender(matchingFlags) {
      return this.props.children && this.props.children ? this.props.children : this.props.renderOn(matchingFlags);
    }
  }, {
    key: 'render',
    value: function render() {
      var _this3 = this;

      var _props = this.props,
          authorizedFlags = _props.authorizedFlags,
          exactFlags = _props.exactFlags;


      return React.createElement(
        FeatureFlags.Consumer,
        null,
        function (flags) {
          var matchingFlags = _this3.matchingFlags(flags);
          if (exactFlags) {
            return matchingFlags.length === authorizedFlags.length ? _this3.resolveRender(matchingFlags) : _this3.props.renderOff(matchingFlags);
          } else {
            return matchingFlags.length ? _this3.resolveRender(matchingFlags) : _this3.props.renderOff(matchingFlags);
          }
        }
      );
    }
  }]);
  return Flags;
}(Component);

Flags.propTypes = {
  authorizedFlags: PropTypes.arrayOf(PropTypes.string).isRequired,
  exactFlags: PropTypes.bool,
  renderOn: PropTypes.func,
  renderOff: PropTypes.func
};
Flags.defaultProps = {
  exactFlags: false,
  renderOn: function renderOn() {
    return null;
  },
  renderOff: function renderOff() {
    return null;
  }
};
var FlagsProvider = function (_Component2) {
  inherits(FlagsProvider, _Component2);

  function FlagsProvider() {
    classCallCheck(this, FlagsProvider);
    return possibleConstructorReturn(this, (FlagsProvider.__proto__ || Object.getPrototypeOf(FlagsProvider)).apply(this, arguments));
  }

  createClass(FlagsProvider, [{
    key: 'render',
    value: function render() {
      var _props2 = this.props,
          children = _props2.children,
          value = _props2.value;

      return React.createElement(
        FeatureFlags.Provider,
        { value: value },
        children
      );
    }
  }]);
  return FlagsProvider;
}(Component);
FlagsProvider.propTypes = {
  value: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    isActive: PropTypes.bool
  })).isRequired
};

export { Flags, FlagsProvider };
